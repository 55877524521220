<template>
  <div
    :data-uid="item.uid"
    :data-name="item.name"
    :data-type="item.type"
    :data-bg-color-key="!bgGradient ? bgColorKey : undefined"
    :class="[
      isAbsolute ? 'absolute' : 'relative',
      textAlign,
      valignClass,
      {
        'box-shadow': hasBoxShadow,
        'rounded-lg':
          item.type === 'item' &&
          (hasBackground || hasBoxShadow) &&
          hasRoundedCorners,
        'p-8': (hasBackground || hasBoxShadow) && item.type === 'item',
        // Admin stuff
        'active-item': isActiveItem,
        'active-sub-block': isActiveSubBlock,
        'active-block': isActiveBlock,
      },
    ]"
    :style="{
      ...itemWrapperStyles,
      ...{
        fontSize,
        cursor,
      },
    }"
    v-on="eventListeners"
  >
    <is-admin v-if="item.type === 'block' && !item.items.length">
      <p>
        <span
          class="
            p-2
            text-sm
            leading-loose
            whitespace-nowrap
            bg-yellow-100
            border border-yellow-300
          "
          >Block is empty. Please add block items from action menu</span
        >
      </p>
    </is-admin>

    <slot v-bind="{ textAlign, shadowVar }" />

    <ItemActions
      v-if="itemActionsShouldBeRendered"
      :class="{ invisible: keepAlive }"
      :actions="actions"
      :item="item"
      :item-title="itemConf.title"
      :is-block="isBlock"
      :has-sibling="hasSibling"
      :has-background="hasBackground"
      :is-sub-block="isSubBlock"
      :is-item="isItem"
      :is-sortable="isSortable"
      :is-deletable="isDeletable"
      :is-duplicateable="isDuplicateable"
      @keepAlive="keepAlive = true"
    />
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import clone from '@/utils/clone'
import models from '@/models'
import colors from '@/mixins/colors'

export default {
  components: {
    ItemActions: () => import('@/components/ItemActions'),
  },

  mixins: [colors],

  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },

    parent: {
      type: Object,
      default() {
        return {}
      },
    },

    isAbsolute: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    keepAlive: false,
    isActiveItem: false,
    isActiveSubBlock: false,
    isActiveBlock: false,
    hasFocus: false,
    timeout: null,
  }),

  computed: {
    isAdminMode: get('isAdminMode'),
    tourIsActive: get('tour/isActive'),
    fullPageLoading: get('fullPageLoading'),
    hasRoundedCorners: get('page/hasRoundedCorners'),

    hasBoxShadow() {
      return !!this.item.props.boxShadow
    },

    valignClass() {
      if (this.item.type !== 'block') {
        return
      }

      // Align middle if single block, if multi-block - use prop setting
      const valign = !this.hasSibling ? 'middle' : this.parent.props.valign

      const valignClass = {
        top: 'lg:items-start',
        middle: 'lg:items-center',
        bottom: 'lg:items-end',
      }[valign]

      return `lg:flex ${valignClass}`
    },

    itemWrapperStyles() {
      if (!this.bgColor && !this.bgGradient) {
        return null
      }

      return {
        ...{ backgroundImage: this.bgGradient },
        ...this.cssColorVars,
      }
    },

    imageItem() {
      return this.item.items.find(item => item.name === 'ImageItem')
    },

    headlineItem() {
      return this.item.items.find(item => item.name === 'Headline')
    },

    bodyTextItem() {
      return this.item.items.find(item => item.name === 'BodyText')
    },

    eventListeners() {
      if (!this.isAdminMode) {
        return
      }

      return {
        mousedown: e => {
          if (!e.ctrlKey) {
            this.hasFocus = true
          }
        },

        mouseover: e => {
          this.keepAlive = false

          if (this.isItem) {
            this.timeout = setTimeout(() => {
              this.isActiveItem = true
              clearTimeout(this.timeout)
            }, 250)
          }

          if (this.isSubBlock) {
            this.isActiveSubBlock = true
          }

          if (this.isBlock) {
            this.isActiveBlock = true
          }
        },

        mouseout: e => {
          clearTimeout(this.timeout)
        },

        mouseleave: e => {
          e.stopPropagation()

          clearTimeout(this.timeout)

          this.hasFocus = false

          if (this.isItem) {
            this.isActiveItem = false
          }

          if (this.isSubBlock) {
            this.isActiveSubBlock = false
          }

          if (this.isBlock) {
            this.isActiveBlock = false
          }
        },
      }
    },

    hasBackground() {
      return !!(
        this.item.props.bgColor?.color || this.item.props.bgColor?.gradient
      )
    },

    isItem() {
      return !this.isSubBlock && !this.isBlock
    },

    isBlock() {
      return this.item.type === 'block'
    },

    isImgItem() {
      return this.item.name === 'ImageItem'
    },

    isSubBlock() {
      return (
        this.item.type === 'item' &&
        ['Feature', 'Card', 'Quote', 'EmailForm', 'Embed'].includes(
          this.item.name
        )
      )
    },

    isAdditonalItem() {
      const haystack = this.parentConf?.root?.additionalItems ?? []
      return haystack.find(item => item.name === this.item.name) !== undefined
    },

    index() {
      return this.parent.items.findIndex(item => item.uid === this.item.uid)
    },

    hasSibling() {
      return this.parent.items.length > 1
    },

    isDeletable() {
      const isBlockOrSubBlock = this.isSubBlock || this.isBlock
      return (
        (isBlockOrSubBlock && this.hasSibling) ||
        (!isBlockOrSubBlock && this.isAdditonalItem)
      )
    },

    isDuplicateable() {
      if (this.isBlock && this.hasSibling) {
        return false
      }

      if (this.item.name === 'PageHeader') {
        return false
      }

      const blackList = {
        parents: ['Card', 'Feature', 'PageHeader', 'EmailForm', 'Quote'],
        items: [
          'Tagline',
          'Icon',
          'Map',
          'ImageItem',
          'EmailForm',
          'Cards:Headline,BodyText',
          'Features:Headline,BodyText',
          'Faq:Headline,BodyText',
          'Quotes:Headline,BodyText',
          'Embed:Headline,BodyText',
        ],
      }

      const itemMatch = blackList.items.some(item => {
        if (item.includes(':')) {
          const [parentName, itemName] = item.split(':')
          if (itemName.includes(',')) {
            return itemName
              .split(',')
              .some(
                subItem =>
                  this.item.name === subItem && this.parent.name === parentName
              )
          }
          return this.item.name === itemName && this.parent.name === parentName
        }

        return this.item.name === item
      })

      return !itemMatch && !blackList.parents.includes(this.parent.name)
    },

    isSortable() {
      if (this.isBlock && !this.hasSibling) {
        return false
      }

      const isSortableItem =
        (this.item.type === 'item' &&
          ['Feature', 'Card', 'Quote', 'Embed', 'FaqItem'].includes(
            this.item.name
          ) &&
          ['Features', 'Cards', 'Quotes', 'Embed', 'Faq'].includes(
            this.parent.name
          )) ||
        this.parent.name === 'Content'
      return (this.isBlock || isSortableItem) && this.hasSibling
    },

    // Classes
    textAlign() {
      const { align } = this.item.props
      if (!align) {
        return
      }
      return `text-${align}`
    },

    // Styles

    fontSize() {
      const { fontSize } = this.item.props

      if (!fontSize) {
        return
      }

      return {
        sm: '90%',
        md: '100%',
        lg: '120%',
        xl: '140%',
      }[fontSize]
    },

    parentConf() {
      return models[this.parent.type + 's']?.[this.parent.name] ?? {}
    },

    itemConf() {
      return models[this.item.type + 's'][this.item.name] ?? {}
    },

    additionalItems() {
      const itemsFromRoot = this.itemConf.root?.additionalItems ?? []

      const itemsFromParent =
        this.parentConf?.[this.item.name]?.additionalItems ?? []

      const additionalItems = clone([...itemsFromRoot, ...itemsFromParent])

      if (additionalItems?.length) {
        additionalItems.map(item => {
          item.canBeAdded = this.additionalItemCanBeAdded(item)
          item.isPro = this.additionalItemIsPro(item)
          return item
        })
      }

      return additionalItems
    },

    parentPropsKeys() {
      return Object.keys(this.parentConf?.[this.item.name]?.props ?? {})
    },

    itemPropsKeys() {
      return Object.keys(this.itemConf?.root?.props ?? {})
    },

    actions() {
      // Make array unique to remove duplicates
      // - Props can be duplicated if set in both item and parent.

      let itemPropsKeys = this.itemPropsKeys

      const hiddenItemProps = this.itemConf?.root?.hiddenProps

      if (hiddenItemProps) {
        itemPropsKeys = itemPropsKeys.filter(
          item => !hiddenItemProps.includes(item)
        )
      }

      const props = [...new Set([...this.parentPropsKeys, ...itemPropsKeys])]

      const actions = []

      // Add settings btn if item has public visible settings
      if (
        this.itemConf.root.settings &&
        this.itemConf.root.hideSettings !== true
      ) {
        actions.push({ name: this.item.name + 'Settings' })
      }

      // Add props as action btn, if any

      if (props.length) {
        const actionsFromProps = props.map(name => {
          let opts = this.itemConf.root.opts?.[name]

          if (!opts) {
            // console.log(this.item.name, this.parentConf?.[this.item.name])
            opts = this.parentConf?.[this.item.name]?.opts?.[name] ?? {}
          }

          return { name, opts }
        })

        actions.push(...actionsFromProps)
      }

      // Add changeParent button if it's a combineable block
      if (
        this.isBlock &&
        models.blocks[this.item.name].canBeCombined !== false
      ) {
        actions.push({
          name: 'changeParent',
          opts: {
            canBeMovedToNewSection: this.parent.items.length > 1,
          },
        })
      }

      // Add additional items btn, if any available
      if (this.additionalItems.length) {
        actions.push({
          name: 'additionalItems',
          opts: {
            additionalItems: this.additionalItems,
          },
        })
      }

      return actions
    },

    hasActions() {
      return this.actions.length || this.isSortable || this.isDeletable
    },

    itemActionsShouldBeRendered() {
      if (
        this.isAdminMode &&
        this.tourIsActive &&
        (this.isImgItem || (this.isBlock && this.item.name === 'Content'))
      ) {
        return true
      }

      return (
        (this.keepAlive ||
          this.isActiveItem ||
          this.isActiveSubBlock ||
          this.isActiveBlock) &&
        this.hasActions
      )
    },

    itemIsText() {
      return ['Headline', 'HeaderBodyText', 'BodyText'].includes(this.item.name)
    },

    cursor() {
      if (this.isItem && this.hasActions && this.isAdminMode && this.hasFocus) {
        return 'text'
      }

      return 'auto'
    },
  },

  watch: {
    fullPageLoading(val) {
      if (!val) {
        this.keepAlive = false
      }
    },
  },

  methods: {
    additionalItemIsPro(item) {
      return models.items[item.name]?.pro
    },

    additionalItemCanBeAdded(additionalItem) {
      const { items } = this.item

      if (additionalItem.max === undefined || !items) {
        return true
      }

      const numCurrentItemsOfTheSameType = items.filter(({ name, alias }) => {
        if (additionalItem.alias) {
          return alias === additionalItem.alias
        }
        return name === additionalItem.name
      }).length

      return numCurrentItemsOfTheSameType < additionalItem.max
    },
  },
}
</script>

<style lang="postcss">
.cursor-wrench {
  cursor: url('~assets/icons/streamline/24/tools-wrench.svg'), auto;
}

.active-item {
  outline: 1px dotted var(--textColor);
}

.active-sub-block {
  outline: 1px dashed var(--textColor);
}

.active-block {
  outline: 1px solid var(--textColor);
}
</style>
