var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({class:[
    _vm.isAbsolute ? 'absolute' : 'relative',
    _vm.textAlign,
    _vm.valignClass,
    {
      'box-shadow': _vm.hasBoxShadow,
      'rounded-lg':
        _vm.item.type === 'item' &&
        (_vm.hasBackground || _vm.hasBoxShadow) &&
        _vm.hasRoundedCorners,
      'p-8': (_vm.hasBackground || _vm.hasBoxShadow) && _vm.item.type === 'item',
      // Admin stuff
      'active-item': _vm.isActiveItem,
      'active-sub-block': _vm.isActiveSubBlock,
      'active-block': _vm.isActiveBlock,
    } ],style:(Object.assign({}, _vm.itemWrapperStyles,
    {
      fontSize: _vm.fontSize,
      cursor: _vm.cursor,
    })),attrs:{"data-uid":_vm.item.uid,"data-name":_vm.item.name,"data-type":_vm.item.type,"data-bg-color-key":!_vm.bgGradient ? _vm.bgColorKey : undefined}},_vm.eventListeners),[(_vm.item.type === 'block' && !_vm.item.items.length)?_c('is-admin',[_c('p',[_c('span',{staticClass:"\n          p-2\n          text-sm\n          leading-loose\n          whitespace-nowrap\n          bg-yellow-100\n          border border-yellow-300\n        "},[_vm._v("Block is empty. Please add block items from action menu")])])]):_vm._e(),_vm._v(" "),_vm._t("default",null,null,{ textAlign: _vm.textAlign, shadowVar: _vm.shadowVar }),_vm._v(" "),(_vm.itemActionsShouldBeRendered)?_c('ItemActions',{class:{ invisible: _vm.keepAlive },attrs:{"actions":_vm.actions,"item":_vm.item,"item-title":_vm.itemConf.title,"is-block":_vm.isBlock,"has-sibling":_vm.hasSibling,"has-background":_vm.hasBackground,"is-sub-block":_vm.isSubBlock,"is-item":_vm.isItem,"is-sortable":_vm.isSortable,"is-deletable":_vm.isDeletable,"is-duplicateable":_vm.isDuplicateable},on:{"keepAlive":function($event){_vm.keepAlive = true}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }